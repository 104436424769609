import request from '@/api/request.js'
// 总后台登录
export function topLogin (data) {
  return request.post('/adm/topLogin', data)
}
//  省市区学校类型
export function provinceCityArea (data) {
  return request.post('/top/provinceCityArea')
}
// 总后台添加学校
export function schoolAdd (data) {
  return request.post('/top/schoolAdd', data)
}
// 总后台添加代理
export function agentAdd (data) {
  return request.post('/top/agentAdd', data)
}
// 属性列表
export function attrList (data) {
  return request.post('/top/attrList')
}
// 标签列表
export function tagList (data) {
  return request.post('/top/tagList')
}
// 总后台添加属性
export function attrAdd (data) {
  return request.post('/top/attrAdd', data)
}
// 总后台添加标签
export function tagAdd (data) {
  return request.post('/top/tagAdd', data)
}
// 总后台文章列表
export function articleList (data) {
  return request.post('/top/articleList', data)
}
// 文章详情
export function articleDetail (data) {
  return request.post('/top/articleDetail', data)
}
// 文章审核
export function articleExamine (data) {
  return request.post('/top/articleExamine', data)
}
// 冻结/解冻
export function thaw (data) {
  return request.post('/top/thaw', data)
}
// 代理学校绑定申请列表
export function bindApply (data) {
  return request.post('/top/bindApply', data)
}
// 代理学校绑定申请处理
export function bindCheck (data) {
  return request.post('/top/bindCheck', data)
}
// 总后台设备列表
// export function machineList (data) {
//   return request.post('/top/machineList', data)
// }
export function machineList (data) {
  return request.post('/erature/machineList', data)
}
// 总后台学校列表
export function schoolList (data) {
  return request.post('/top/schoolList', data)
}
// 总后台学校设置数据源
export function schoolSource(data) {
  return request.post('/top/schoolSource', data)
}
// 总后台添加广告主
export function Addadvertising (data) {
  return request.post('/adv/advUserAdd', data)
}
// 总后台广告主列表
export function advUserList (data) {
  return request.post('/adv/advUser', data)
}
// 总后台广告主充值记录
export function advRechargeRecord (data) {
  return request.post('/adv/advRechargeRecord', data)
}
// 总后台为广告主充值
export function advRecharge (data) {
  return request.post('/adv/advRecharge', data)
}
// 总后台APP首页数据修改
export function setIndex (data) {
  return request.post('/data/setIndex', data)
}
// 总后台APP添加文章点赞和阅读数量
export function setReadGood (data) {
  return request.post('/data/setReadGood', data)
}
// 总后台APP添加文章评论
export function setMessage (data) {
  return request.post('/data/setMessage', data)
}
// 总后台APP添加文章评论用户列表
export function setUserList (data) {
  return request.post('/data/setUserList', data)
}
// 总后台APP添加用户
export function setUser (data) {
  return request.post('/data/setUser', data)
}
// 总后台APP用户上传头像
export function img (data) {
  return request.post('/upload/img', data)
}
// 总后台APP获取首页数据
export function getIndex () {
  return request.post('/data/getIndex')
}
// 总后台修改文章内容
export function setArticleinfo (data) {
  return request.post('/top/setArticleinfo',data)
}
// 总后台添加年级
export function classTypeAdd (data) {
  return request.post('/school/classTypeAdd',data)
}
// 总后台年级级别信息
export function classTypeLevel () {
  return request.post('/school/classTypeLevel')
}
// 总后台添加班级
export function classAdd (data) {
  return request.post('/school/classAdd',data)
}
// 总后台查看年级列表
export function classTypeList (data) {
  return request.post('/school/classTypeList',data)
}
// 总后台查看班级列表
export function classList (data) {
  return request.post('/school/classList',data)
}
// 总后台查看学生列表
export function getStudentsList (data) {
  return request.post('/student/getStudentsList',data)
}
// 总后台学校数据统计
export function schoolData (data) {
  return request.post('/data/schoolData',data)
}
// 总后台检测数据统计
export function testData () {
  return request.post('data/testData')
}
// 总后台广告列表
export function advList (data) {
  return request.post('adv/advList',data)
}
// 总后台广告商申请列表
export function advAuthList (data) {
  return request.post('erature/advUserAuthApply',data)
}
// 总后台广告商认证审核
export function advAuth (data) {
  return request.post('erature/advUserAuthStatus',data)
}

// 总后台广告商认证审核记录
export function advAuthLog (data) {
  return request.post('erature/advUserAuthStatusList',data)
}
// 总后台广告详情
export function advDetail (data) {
  return request.post('adv/advDetail',data)
}
// 总后台广告审核
export function advExamine (data) {
  return request.post('adv/advExamine',data)
}
// 总后台活动得分排行
export function getRankList (data) {
  return request.post('student/getRankList',data)
}
// 总后台代理列表
// export function agentList (data) {
//   return request.post('agent/agentList',data)
// }
// 总后台设置代理广告分成比例
export function agentAdvShare (data) {
  return request.post('agent/agentAdvShare',data)
}
// 总后台设置app版本更新管理
export function uploadPackage (data) {
  return request.post('upload/package',data)
}
// 总后台设置文章置顶
export function articleTop (data) {
  return request.post('top/articleTop',data)
}
// 总后台app发布文章
export function pushNews (data) {
  return request.post('data/pushNews',data)
}
// 总后台app发文章用户列表
export function pushUser (data) {
  return request.post('data/pushUser',data)
}
// 总后台app实名认证列表
export function realauthList (data) {
  return request.post('arrive/realauthList',data)
}
// 总后台app实名认证审核
export function realauth (data) {
  return request.post('arrive/realauth',data)
}
// 总后台app实名认证申诉列表
export function appealList (data) {
  return request.post('arrive/appealList',data)
}
// 总后台app实名认证申诉审核
export function appeal (data) {
  return request.post('arrive/appeal',data)
}
// 总后台app学校数据导入学校查询
export function excelSchoolList (data) {
  return request.post('top/excelSchoolList',data)
}
// 总后台app学校数据导入
export function excelAdd (data) {
  return request.post('top/excelAdd',data)
}
// 总后台数据管理地区查询
export function citySearch () {
  return request.post('data/citySearch')
}
// 总后台数据管理学校查询
export function schoolSearch (data) {
  return request.post('data/schoolSearch',data)
}
// 添加广告商
export function AddadvShangertising (data) {
  return request.post('adv/advShangUserAdd',data)
}
// 广告商列表
export function advShangUser (data) {
  return request.post('adv/advShangUser',data)
}
// 广告商充值
export function advShangRecharge (data) {
  return request.post('adv/advShangRecharge',data)
}
// 广告商充值列表
export function advShangRechargeList (data) {
  return request.post('adv/advShangRechargeList',data)
}
// 省市区
export function ssq (data) {
  return request.post('adm/ssq',data)
}

//##################################新增##################################
// 代理 等级列表
export function agentLevel (data) {
  return request.post('agent/agentLevel',data)
}
// 代理等级分成修改/创建代理等级
export function agentLevelEdit (data) {
  return request.post('agent/agentLevelEdit',data)
}
// 修改代理等级
export function agentLevelLookchange (data) {
  return request.post('agent/agentLevelLookchange',data)
}
// 修改代理时候查询所有合伙人
export function zhtpartnerList () {
  return request.post('agent/zhtpartnerList')
}
// 修改代理归属
export function agentBelong (data) {
  return request.post('agent/agentBelong',data)
}
// 查询城市合伙人列表
export function partnerListAll (data) {
  return request.post('agent/partnerListAll',data)
}
// 添加合伙人查询合伙人等级
export function partnerLevel (data) {
  return request.post('agent/partnerLevel',data)
}
// 合伙人等级分成修改/创建代理等级
export function partnerLevelAdd (data) {
  return request.post('agent/partnerLevelAdd',data)
}
// 添加合伙人
export function partnerAdd (data) {
  return request.post('agent/partnerAdd',data)
}
// 添加大屏幕用户
export function screenAdd (data) {
  return request.post('screen/screenAdd',data)
}
// 大屏幕用户列表
export function screenListAll (data) {
  return request.post('screen/screenList',data)
}
// 设备注册审核列表
export function machineVerify (data) {
  return request.post('agent/machineVerify',data)
}
// 设备注册审核列表 状态修改
export function machineVerifyEdit (data) {
  return request.post('agent/machineVerifyEdit',data)
}
// 设备注册接口地址
export function machineVerifyApiAddr (data) {
  return request.post('agent/machineVerifyApiAddr',data)
}
//设备批次管理
export function machines (data){
	return request.post('erature/batchList',data)
}
//批次创建
export function machinesAddBatch(data){
	return request.post('erature/addBatch',data)
}
//校温管理
export function revisionList(data){
	return request.post('erature/revisionList',data)
}
//批次管理
export function machineBatchList(data){
	return request.post('erature/machineBatchList',data)
}
//校温创建
export function addRevision(data){
	return request.post('erature/addRevision',data)
}
//校温修改
export function editRevision(data){
	return request.post('erature/editRevision',data)
}
//更换批次
export function machineEditBatch(data){
	return request.post('erature/machineEditBatch',data)
}
//设备注册
export function registerMachineApply(data){
	return request.post('erature/registerMachineApply',data)
}
//校温删除
export function revisionDel(data){
	return request.post('erature/revisionDel',data)
}
//设置设备属性
export function cameraSet(data){
	return request.post('erature/cameraSet',data)
}
//设备类型
export function machineType(data){
	return request.post('erature/machineType',data)
}
//注册人列表
export function registerUserList(data){
	return request.post('erature/registerUserList',data)
}
//代理列表
export function agentList(data){
	return request.post('agent/agentList',data)
}
//设备异常登录日志
export function machineErrorLogin(data){
	return request.post('erature/machineErrorLogin',data)
}
//设备异常登录日志
export function machineRegisterLogin(data){
	return request.post('erature/machineRegisterLogin',data)
}
//注册设备申请审核
export function registerApprove(data){
	return request.post('erature/registerApprove',data)
}
//设备错误类型列表
export function machineErrorType(data){
	return request.post('erature/machineErrorType',data)
}

//注册读卡器
export function registerReader(data){
	return request.post('erature/raedReg',data)
}

//注册读卡器
export function makeKid(data){
	return request.post('erature/makeKid',data)
}
//注册读卡器
export function kidHead(data){
	return request.post('erature/kidHead',data)
}

//举报列表
export function report(data){
	return request.post('erature/report',data)
}
//举报列表
export function question(data){
	return request.post('erature/question',data)
}

//文章冻结、解冻
export function freeze(data){
	return request.post('top/thaw',data)
}
